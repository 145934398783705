import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser} style={{border: 0}}>
    <div className={styles.descUser}>
      <img
        src="/images/about/giulia/giulia_logo.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      Everything is under control when Giulia Prawn is in the area, no fucking around! You’ll find here behind
the merch stand at most COdA gigs or solving some nonsense caused by Inkjet.
      </p>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/giulia/giulia_img.png"
        className={styles.imageUser}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div className={styles.review}>
    <div className={styles.logodesc}>
      <img
        src={props.logo}
        className={styles.logo}
        alt=""
      />
      <div style={{ textAlign: "center" }} className={styles.description}>
        <p style={{ float: "right" }} className={styles.excerpt}>{props.excerpt}</p>
      </div>
    </div>
    <div>
      <a href={props.link} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
        <img src={props.image} className={styles.image} alt="" />
      </a>
    </div>
  </div>
);

export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      <div style={{textAlign: "center"}}>
        <img src="/images/about/giulia/the-hacker-matrix.gif" width={480} height={480} alt="coding" />  
      </div> 
    </Container>
  </Layout>
);
